import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_solutions.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p className="leading-relaxed text-lg">
  Provide all necessary information in one app by adding it to Trailguide. The
  app makes it easy to find any activites that fit the abilities and wishes of
  your guests, no matter if it is a long and challenging MTB tour or an easy
  walk to a nearby lake.
    </p>
    <Image src="solutions/informyourguests4.png" className="my-12 w-3/4 mx-auto" mdxType="Image" />
    <h4>{`All information available in one place`}</h4>
    <p>{`Provide all the information your guests need to know about the activities.
Everything is accessible from the desktop computer at home, and from the
phone outdoors.`}</p>
    <br />
    <p>{`Pictures, videos, description, local services, latest conditions, the location
or the track with its height profile, its length, ascends and descends.`}</p>
    <br />
    <p><a parentName="p" {...{
        "href": "https://trailguide.net/3"
      }}>{`Click here to open a typical activity page`}</a>{`.`}</p>
    <a href="https://trailguide.net" target="tg">
  <Image src="solutions/activitypage.png" className="my-12 w-3/4 mx-auto" mdxType="Image" />
    </a>
    <br />
    <br />
    <h4>{`Find suitable activities`}</h4>
    <p>{`The trailguide app opens with an interactive map allowing your guests to
intuitively explore your destination by scanning and zooming through the map,
selecting interesting activities in your area.`}</p>
    <br />
    <p>{`All activities can also be accessed through a list wheren the guest can search
and filter for suitable activities.`}</p>
    <Image src="solutions/mapandlist2.png" className="my-12 w-3/4 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`Filter and search`}</h4>
    <p>{`An available filter function enables your guests to narrow down search results
to their specific wishes and abilities. Filter for type of activity, difficulty,
length, conditions or recommendations.`}</p>
    <Image src="solutions/searchandfilter4.png" className="my-12 w-3/4 mx-auto" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      